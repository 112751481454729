// Packages
import React, { useEffect } from 'react'

// Styled Components
import {
  StoreNotFoundContent,
  StoreNotFoundImage,
  StoreNotFoundDescrition
} from './styledComponents'

// Assets
import Store from '../../data/assets/images/store.svg'
import { useSelector } from 'react-redux'
import { reducersTypes } from '../../redux/reducers'
import { navigate } from 'gatsby'

const StoreNotFoundContainer: React.FC = () => {
  const { profile } = useSelector((state: reducersTypes) => state)

  const { user_data } = profile

  useEffect(() => {
    if (user_data) {
      if (
        user_data.getProfile &&
        user_data.getProfile?.stores &&
        user_data.getProfile?.stores.length > 0
      ) {
        navigate(-2)
        return
      }
    }
  }, [user_data])

  return (
    <>
      <StoreNotFoundContent>
        <StoreNotFoundImage src={Store} />
        <StoreNotFoundDescrition>
          Aún no cuentas con un Modelorama asignado, ponte en contacto con tu
          Agencia.
        </StoreNotFoundDescrition>
      </StoreNotFoundContent>
    </>
  )
}

export default StoreNotFoundContainer
