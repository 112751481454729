import styled from 'styled-components'

export const StoreNotFoundContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`

export const StoreNotFoundImage = styled.img`
  width: 130px;
`

export const StoreNotFoundDescrition = styled.span`
  color: #828282;
  text-align: center;
  font-size: 16px;
  max-width: 240px;
  margin-top: 40px;
`
