import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../../data/env'
import cover from '../../data/assets/images/backgroundLogin.webp'
import { useLocation } from '@reach/router'

type Props = {
  description?: string
  lang?: 'es' | 'en'
  meta?: any[]
  title: string
}

const SearchEngineOptimization: React.FC<Props> = ({
  description = '',
  lang = 'es',
  meta = [],
  title
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata.title
  return (
    <Helmet
      htmlAttributes={{ lang: lang }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `viewport`,
          content: `width=device-width`
        },
        {
          name: 'og:image',
          content: cover
        }
      ].concat(meta)}
    >
      <link rel="canonical" href={config.app.url} />
      {
        /* istanbul ignore next */
        !useLocation().pathname.includes('/auth') &&
          process.env.GATSBY_ACTIVE_ENV === 'staging' &&
          config.zendesk.key && (
            <script
              id="ze-snippet"
              key="ze-snippet-script"
              type="text/javascript"
              src={`https://static.zdassets.com/ekr/snippet.js?key=${config.zendesk.key}`}
            ></script>
          )
      }
    </Helmet>
  )
}

export default SearchEngineOptimization
