// Packages
import React from 'react'

// Components
import SearchEngineOptimization from '../../../components/SEO'
import StoreNotFoundContainer from '../../../containers/StoreNotFoundContainer'
import LayoutMain from '../../../layout'

const storeNotFound: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Modeloramas" />
      <StoreNotFoundContainer />
    </LayoutMain>
  )
}

export default storeNotFound
